import React from "react";
import './Photo.css'

export default function Photo({photos = [], albumTitle = "None", id=0}) {
  if(photos.length===0) photos.push('./logo512.png');

  return photos.length<=1?
        <div className="Photo" id={`${albumTitle + (id+1)}`} data-index={id+1} style={{backgroundImage: `url(${photos[0]})`}}>

        </div>
        :
        <div className="Photo multiple" id={`${albumTitle + (id+1)}`}>
          {
            photos.map((photo, index) => {
              //console.log(albumTitle, photo);
              return <div key={`pm${albumTitle + index}`} style={{backgroundImage: `url(${photo})`}}></div>;
            })
          }
        </div>
  ;
}