import React from "react";
import Section from "../Section";
import Album from "../Album";

import getAlbums from "../../services/getAlbums";

export default function GallerySection() {
  const albums = getAlbums({resolution: '2x'}).albums || [];

  return (
    <>
      {
        albums.map((album, index) => {
          return <Section key={`s${index}`} index={index} letter="s" content={<Album album={album} noblack={index%2===0} />}/>;
        })
      }
    </>
  )
}