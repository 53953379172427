export default function getAlbums({resolution = 'original'}) {
  //console.log(resolution);

  const rootPath = "images/";
  let suffix = "";
  let preffix = "";

  switch(resolution) {
    case "2x":
      preffix = resolution + "/";
      suffix = "-scale-2_00x";
      break;
    case "4x":
      preffix = resolution + "/";
      suffix = "-scale-4_00x";
      break;
    default: 
      suffix = "";
      preffix = "";
      break;
  }

  return {
    albums:[
      {
        "title": "Outdoor",
        "basePath": `${rootPath}Outdoor/`,
        "resolution": resolution,
        "preffix": preffix,
        "suffix": suffix,
        "photos":[
          ['0006 edit - garden from tech - SML.jpg'],
          [
            '0008 - Deep Green - SML.jpg',
            '0010 - tech view - SML.jpg',
            '0027 - LR from V3 - SML 1.jpg',
          ],
          ['0014  - LR windows from Garden - SML.jpg'],
          [
            '0062 - Atico from Garden - SML.jpg',
            '0065 - pergola - SML.jpg',
          ],
          ['0074 edit - Main Entrance - SML.jpg']
        ]
      }, 
      {
        "title": "The Control Room",
        "basePath": `${rootPath}TheControlRoom/`,
        "resolution": resolution,
        "preffix": preffix,
        "suffix": suffix,
        "photos":[
          ['0032 - CR Fish Eye from Back - SML.jpg'],
          ['0039 - CR Fish Eye Horizont From Back SML.jpg'],
          ['0054 - Orange Outboard Racks - SML.jpg'],
          ['0078 - CR Blue Front - SML.jpg'],
          ['l_f3d8836717accda9a4fab7da0551007d.jpg']
        ]
      },
      {
        "title": "The Live Room",
        "basePath": `${rootPath}TheLiveRoom/`,
        "resolution": resolution,
        "preffix": preffix,
        "suffix": suffix,
        "photos":[
          ['0219 - Day View From Mez Horizont - SML.jpg'],
          ['0221 LR Day view from Mez - SML.jpg.jpg'],
          ['l_1dec2fec38816670eb817705ed36cc2f.jpg'],
          ['l_8d6480f4402523dd662fc2c9da5d4a4f.jpg'],
          ['l_75104533a0f31ec455437d5978f24f78.jpg'],
          ['l_c6f58df293f524e2883925a636cd35d1.jpg'],
        ]
      },
      {
        "title": "Under & Above The Mezzanine",
        "basePath": `${rootPath}UnderAndAboveTheMezzanine/`,
        "resolution": resolution,
        "preffix": preffix,
        "suffix": suffix,
        "photos":[
          [
            '0136 Mez Blue Top-Warm Under - SML.jpg',
            '0243 - Warm Under Mez @ Day V2 - SML.jpg'
          ],
          ['0140 - Blue Mez from Staircase - SML.jpg'],
          ['0179 - Mezz From CB1 - SML.jpg'],
          ['0191 - Under Mezz Fish Eye from Staircase - SML.jpg'],
        ]
      },
      {
        "title": "More",
        "basePath": `${rootPath}More/`,
        "resolution": resolution,
        "preffix": preffix,
        "suffix": suffix,
        "photos":[
          ['l_3dbf969944c13925f0282775eab1ba2b.jpg'],
          ['l_6a4ada3e3c704848d6e1a3af81e39f3e.jpg'],
          ['l_8ff0de829009cc8aa74e09c6fd29569e.jpg'],
          [
            'l_3575c7bfd3517c4644bce43852bebd78.jpg',
            'l_a0affc6c45e44537912c6eed7cd24571.jpg'
          ],
        ]
      },
      {
        "title": "Creating Monasterio",
        "basePath": `${rootPath}CreatingMonasterio/`,
        "resolution": resolution,
        "preffix": preffix,
        "suffix": suffix,
        "photos":[
          ['l_2c30539cbfa1b168311406d100aca6c5.jpg'],
          ['l_3dde5a47aabbfd8db255dcda7ccda144.jpg'],
          [
            'l_6d0d5919e8dde146c5dff77f4e6b630f.jpg',
            'l_3f51cedffb664308de3cce8e1be9a581.jpg',
          ],
          ['l_8fcfd01c4fe6ec47994b4870d05009f8.jpg'],
          ['l_48b583d7623b8a49ca3c8581d2e74afc.jpg'],
          ['l_69b2687fbb08b0ef58aaafc78cba80ad.jpg'],
          ['l_376bec13eb4f1f645d26e6ae29de3c37.jpg'],
          ['l_1414a70a7e13e3399ae9e25ea3e8dd10.jpg'],
          ['l_20413cfd503b201e2fd77563ec26d6a6.jpg'],
          ['l_acd563b09a6e653e386545a5c88efafc.jpg'],
          ['l_c067382316b637eefddf32dc6732fe90.jpg'],
          ['l_c60501229801ca90f624ebddec244081.jpg'],
          ['l_d3fa35b7cfef1b337cac9520bca1abf8.jpg'],
          ['l_d390535fedf6839254db29512d8a0c62.jpg'],
          ['l_d6036569e4b234862f5ad55cf0009721.jpg'],
          ['l_da6ad0ba1ae5560a1152dc0674c2af76.jpg'],
          ['l_ddaec9246b22e89ed946030e5b82e9fc.jpg'],
          ['l_3f51cedffb664308de3cce8e1be9a581.jpg'],

        ]
      }
    ]
  };
}