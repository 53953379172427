import React from "react";
import AboutSection from "../../components/AboutSection";
import GallerySection from "../../components/GallerySection";
import HomeSection from "../../components/HomeSection";

export default function Home() {
  return (
    <>
      <HomeSection></HomeSection>
      <GallerySection></GallerySection>
      <AboutSection></AboutSection>
    </>
  )
}