import React, { useRef, useState } from "react";
import getCredits from "../../services/getCredits";
import Credit from "../Credit";
import Fa from "react-fontawesome";
import './About.css'

export default function About(props) {
  const [angleLeft, setAngleLeft] = useState(false);
  const credits = getCredits();

  const aboutDescription = useRef();
  const aboutCredits = useRef();
  const sliderRef = useRef();

  const handleAngleClick = (event) => {
    //setAngleLeft(!angleLeft);

    if(angleLeft){
      aboutDescription.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      aboutCredits.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleScroll = (event) => {
    setAngleLeft(Math.round(sliderRef.current.scrollLeft/window.innerWidth));
  }

  return (
    <div className="About">
      <div className="title-wrapper">
        <h2 className="title">About</h2>
        <Fa className="angle" name={angleLeft?"angle-left":"angle-right"} onClick={handleAngleClick}></Fa>
      </div>
      
      <div className="content" onScroll={handleScroll} ref={sliderRef}>
        <div className="description" id="about-description" ref={aboutDescription}>
          <p>
            The highest quality sought down to the littlest details: a risky philosophy that, after 7 years of hard work, brought fantastic results at Monasterio.
          </p>
          <p>
            Acoustics, instruments, microphones, preamps, outboard gear, signal path, cabling, monitors, ergonomics; every aspect was reviewed in every possible angle, so that the combination would be nothing less than pure and unobtrusive to the making of music.
          </p>
          <p>
            From the musician's hand to the final mix, the choices available are the best, combining  a great mic collection and the finest analog equipments made to date (starting with a showroom collection of vintage Neve mic preamps, and then more Neve, GML, Manley, API, Tubetech and Universal Audio) with the most the digital world has to offer (in countless track count and Apogee conversion), and finishing off into 32 channels of pristine analog summing by Manley.
          </p>
          <p>
            And get tons of toys in the process, if your music asks for it. From the loudest to near silence, the dirtiest to the cleanest, any kind of music can find its perfect place in such a complete studio. A real manifest towards excellence.
          </p>
          <p>
            The main room is designed to support variable acoustics and has a larger than usual iso booth, capable of fitting drums, piano or any other large acoustic instruments requiring isolation with room to spare. Tie-line runs allow for several amplifiers to be placed in smaller isolated areas throughout the studio's many adjoining rooms.
          </p>
          <p>
            Icing on the cake, Monasterio also has a fine collection of instruments, including drum kits, pianos, guitars, amps, percussions, pedals, all sorts of keyboards and crazy toys. In short, it's an "all you can dream" space to record and mix music (in 2.0 or 5.1).
          </p>
          <p>
            Oh, and the control room is huge!!!
          </p>
          <p className="Alencar">
            <b>Alvaro Alencar</b><br/>
            Alvaro Alencar has over 100 records in his career spanning 14 years. In this time he has won over 14 Latin Grammy Awards, over 20 Latin Grammy nominations, 1 Grammy nomination, multiple diamond, platinum and gold certificates for CD and DVD sales.
          </p>
        </div>

        <div className="credits" id="about-credits" ref={aboutCredits}>
          <div className="logo">
            <img src="images/fantasmita.png" alt="Monasterio Pet" />
          </div>
          { credits.map((credit, index) => <Credit key={`cr${index}`} credit={credit}/> ) }
        </div>
      </div>
    </div>
  )
}