export default function getCredits(){
  return [
    {name: "Antoine de Castellane",       position:"Concept and Design"},
    {name: "Michael Blackmer",            position:"Acoustic Design"},
    {name: "Alvaro Alencar",              position:"Audio and technical Consulting"},
    {name: "Ground Control",              position:"Installation and Systems Design"},
    {name: "Raoni Andrade",               position:"In-House Engineer"},
    {name: "Respawn AVP",                 position:"Management"},
    {name: "Claudia Baldeón Villagómez",  position:"Contact",  email: "contact@studio.ec"},
  ]
}