import React, {useState, useRef, useEffect} from "react";
import Fa from "react-fontawesome";
import Photo from "../Photo";
import './Album.css';

export default function Album({album = {photos: []}, noblack=false}) {
  const [currentIndex, setIndex] = useState(1);
  const [leftEnabled, setLeftEnabled] = useState(false);
  const [rightEnabled, setRightEnabled] = useState(true);
  const [mouseMoving, setMouseMoving ] = useState(true);
  const [idTimeout, setIdTimeout] = useState(null);
  const [coords, setCoords] = useState({x: 0, y: 0});

  const sliderRef = useRef();

  function parsePhotoUrl(album, photo) {
    const tmpPhoto = photo.split(".");
    const extension = tmpPhoto.pop();
    const basePhoto = tmpPhoto.join(".");

    return encodeURI(`${album.basePath + album.preffix + basePhoto + album.suffix + "." + extension }`);
  }

  const handleScroll = (event) => {
    setIndex(Math.round(sliderRef.current.scrollLeft/window.innerWidth) + 1);

    if(currentIndex===1) setLeftEnabled(false); else setLeftEnabled(true);
    if(currentIndex===album.photos.length) setRightEnabled(false); else setRightEnabled(true);
  }

  const handleLeft = (event) => {
    if(currentIndex > 1){
      document.getElementById(`${album.title + (currentIndex-1)}`).scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleRight = (event) => {
    if(currentIndex < (album.photos.length)){
      document.getElementById(`${album.title + (currentIndex+1)}`).scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleMouseMove = (event) => {
    const moveX = event.clientX - event.target.offsetLeft;
    const moveY = event.clientY - event.target.offsetTop;
    const threshold = 5;

    if(Math.abs(moveX - coords.x) > threshold || Math.abs(moveY - coords.y) > threshold){
      setCoords({
        x: moveX,
        y: moveY,
      });

      setMouseMoving(true);
      clearTimeout(idTimeout);
      setIdTimeout(setTimeout(handleHide, 1000));  
    }
  }

  const handleHide = (evt)=>{
    setMouseMoving(false);
    setIdTimeout(null);
  }

  const handleMouseLeave = () => {
    setMouseMoving(true);
  }

  const handleMouseEnter = () => {
    setMouseMoving(true);
    setIdTimeout(setTimeout(handleHide, 2000));
  }

  const handleClick = () => {
    setMouseMoving(!mouseMoving);
  }


  return (
    <div className={mouseMoving?"Album":"Album quiet"} 
      onMouseMove={handleMouseMove} 
      onMouseLeave={handleMouseLeave} 
      onMouseEnter={handleMouseEnter}
      
    >
      <div className={noblack?"title-wrapper noblack":"title-wrapper"}>
        <h2 className="title">{album.title || 'No title'}</h2>
      </div>

      <div className="photos-wrapper" onScroll={handleScroll} onClick={handleClick} ref={sliderRef} >
        {
          album.photos.map((photo, index)=>{
            let photoSrcs = [];
            photo.forEach(ph => photoSrcs.push(parsePhotoUrl(album, ph)));

            return <Photo key={`p${index}`} id={index} photos={photoSrcs} albumTitle={album.title}></Photo>
          })
        }
      </div>

      <div className="angle left">
        <Fa name="angle-left" onClick={handleLeft} className={leftEnabled?'':'disabled'}></Fa>
      </div>

      <div className="angle right">
        <Fa name="angle-right" onClick={handleRight} className={rightEnabled?'':'disabled'}></Fa>
      </div>

      <div className="counter">
        <h4>{`${currentIndex}/${album.photos.length}`}</h4>
      </div>
    </div>
  )
}