import React from "react";
import Fa from "react-fontawesome";
import './Ventanamon.css';

export default function Ventanamon() {
  console.log('ventanamon');

  function handleDown(){
    document.getElementById('s0').scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <>
      <img className="ventanamon-logo" src="images/ventanamon1.jpg" alt="Monasterio Logo"/>
      <Fa name="angle-down" onClick={handleDown} className="hint-down"></Fa>
    </>
  );
}