import React from "react";
import './Credit.css';

export default function Credit({credit}) {

  return (
    <div className="Credit">
      <h5 className="position">{credit.position}</h5>
      <h6 className="person">{credit.name}</h6>
      {
        credit.email?<a href={`mailto:${credit.email}`} className="email">{credit.email}</a>:''
      }
    </div>
  )
}