import React from "react";
import './Section.css'

export default function Section(props) {

  return (
    <div className="Section" id={`${props.letter + props.index}`} style={{backgroundColor: props.color || 'black'}}>
      {props.content || <h1>No content</h1>}
    </div>
  )
}